import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { CartOverviewLineItem } from '../..'
import { context } from '../../../store'
import { l } from '../../../lib/locale'

import Image from '../../Content/BlockHomePageHero/image'

const { pages } = require('../../../config/pages.json')
const cartPage = pages.find(i => i.id === 'cart')

const CartOverview = ({ lang }) => {
  const [loading, setLoading] = useState(false)
  const { store, cartProvider, layoutProvider } = useContext(context)
  const { cart, disabled, adding, removing, updating, removingvoucher } = store && store.cart
    ? store.cart
    : { cart: null, disabled: false, adding: false, removing: false, updating: false, removingvoucher: false }

  const goToCheckout = async (e) => {
    setLoading(true)
    await cartProvider.initCheckout(lang, navigate)
  }

  const onAddVoucher = (e) => {
    if (e) e.preventDefault()
    layoutProvider.showAddVoucher()
  }

  const onRemoveVoucher = (e, rule) => {
    if (e) e.preventDefault()
    cartProvider.removeVoucher(rule)
  }

  const onRefresh = (e) => {
    if (e) e.preventDefault()
    cartProvider.refresh()
  }

  if (cart && cart._embedded && cart._embedded.items && cart._embedded.items.length > 0) {
    const cartChanged = cart._embedded.items.reduce((a,b) => a || b.removed, false);

    return (
      <div className="row">
        <div className="col-md-8">
          <h3 className="shopping-bag-title">{cartPage[`title_${lang.suffix}`]}</h3>
          <p className="shopping-bag-counter-mobile">{cart._embedded.items.length} {l('cart-articles-for', lang)} &euro; {Number(cart.totals.total_paid).toFixed(2)}</p>

          <div className="row m-3">
            <div className="col-12">
              <Image className="hero-image" alt="" lang={lang} />
            </div>
          </div>

          {cartChanged ? (
            <div className="alert alert-warning mt-3">
              <p>{l('msg-cartitems-removed', lang)}</p>
              <button className="btn btn-warning" disabled={disabled} onClick={onRefresh}>{l('button-confirm', lang)}</button>
            </div>
          ) : ''}

          {l('msg-webshop-attention', lang) && (
            <div className="alert alert-warning mt-3">
              {l('msg-webshop-attention', lang)}
            </div>
          )}

          {cart._embedded.items.map(item => (
            <CartOverviewLineItem
              key={`shopping-bag-item-${item.id}`}
              cartProvider={cartProvider}
              layoutProvider={layoutProvider}
              item={item}
              disabled={disabled}
              lang={lang} />
          ))}

        </div>

        <div className="col-md-4 summary">
          <h3>{l('cart-summary', lang)}</h3>
          <div className="summary-line d-flex justify-content-between">
            <p>{l('cart-subtotal', lang)}</p>
            <p>&euro; {Number(cart.totals.total_products_wt).toFixed(2)}</p>
          </div>
          <div className="summary-line d-flex justify-content-between">
            <p>{l('cart-shipping', lang)}</p>
            <p>&euro; {Number(cart.totals.total_shipping_tax_incl).toFixed(2)}</p>
          </div>
          <div className="summary-line d-flex justify-content-between">
            <p>{l('cart-discount', lang)}</p>
            <p>- &euro; {Number(cart.totals.total_discounts_tax_incl).toFixed(2)}</p>
          </div>
          {cart && cart._embedded && cart._embedded.rules ? (
            <>
              <hr />
              {cart._embedded.rules.map(rule => {
                return (
                  <div className="summary-line d-flex justify-content-between" key={`cart-rule-${rule.id_cart_rule}`}>
                    <p>
                      {rule._links && rule._links.remove ? (
                        <button className="btn btn-link" disabled={disabled} data-rule={rule} onClick={e => onRemoveVoucher(e, rule)}>
                          <i className={`fas ${removingvoucher && removingvoucher === rule.id_cart_rule ? 'fa-spin fa-spinner' : 'fa-times'}`}></i>
                        </button>
                      ) : ''}
                      <span>{rule[`description_${lang.suffix}`] || rule.code || rule[`name_${lang.suffix}`]}</span>
                    </p>
                    {rule.computed_amount > 0 ? (
                      <p className="pt-1">- &euro; {Number(rule.computed_amount).toFixed(2)}</p>
                    ) : rule.reduction_amount ? (
                      <p className="pt-1">- &euro; {Number(rule.reduction_amount).toFixed(2)}</p>
                    ) : rule.reduction_percent ? (
                      <p className="pt-1">- {Number(rule.reduction_percent).toFixed(0)}%</p>
                    ) : null}
                  </div>
                )
              })}
            </>
          ) : ''}
          <hr />
          <div className="summary-line d-flex justify-content-between">
            <p>{l('cart-total-incl-vat', lang)}</p>
            <p>&euro; {Number(cart.totals.total_paid_tax_incl).toFixed(2)}</p>
          </div>
          <div className="summary-line d-flex justify-content-between">
            <p>{l('cart-vat', lang)}</p>
            <p>&euro; {Number(cart.totals.total_vat).toFixed(2)}</p>
          </div>
          <hr />
          <div className="summary-line">
            <button className="btn btn-outline-secondary btn-block" onClick={onAddVoucher} disabled={disabled}>
              {l('cart-have-promo-code', lang)} <i className="fas fa-chevron-right mx-1"></i>
            </button>
          </div>
          <button className="btn-medium-secondary btn-add-to-cart" disabled={disabled || loading} onClick={goToCheckout}>
            <i className={`fas ${loading || adding || updating || removing ? 'fa-spin fa-spinner' : 'fa-chevron-right'}`}></i> {l('button-checkout', lang)}
          </button>
          <hr />
          <div className="summary-line summary-features">
            <ul>
              <li><i className={`fas fa-check fa-xs mr-2`} />{l('cart-features-shipping', lang)}</li>
              <li><i className={`fas fa-check fa-xs mr-2`} />{l('cart-features-personal', lang)}</li>
              <li><i className={`fas fa-check fa-xs mr-2`} />{l('cart-features-packing', lang)}</li>
              <li><i className={`fas fa-check fa-xs mr-2`} />{l('cart-features-retour', lang)}</li>
              <li><i className={`fas fa-check fa-xs mr-2`} />{l('cart-features-shop', lang)}</li>
            </ul>
          </div>
          <div className="summary-line summary-paymentmethods">
             <ul>
              <li><img src="https://www.mollie.com/external/icons/payment-methods/bancontact.svg" /></li>
              <li><img src="https://www.mollie.com/external/icons/payment-methods/ideal.svg" /></li>
              <li><img src="https://www.mollie.com/external/icons/payment-methods/creditcard.svg" /></li>
              <li><img src="https://www.mollie.com/external/icons/payment-methods/paypal.svg" /></li>
              <li><img src="https://www.mollie.com/external/icons/payment-methods/kbc.svg" /></li>
              <li><img src="https://www.mollie.com/external/icons/payment-methods/belfius.svg" /></li>
             </ul>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <p>{l('msg-empty-cart', lang)}</p>
        </div>
      </div>
    )
  }
}

CartOverview.propTypes = {
  lang: PropTypes.object
}

export default CartOverview
