import React, { useState } from "react"
import PropTypes from "prop-types"
import { PageLink, CheckoutButton, SKUAvailabilityLabel } from "../.."
import { l, t } from "../../../lib/locale"
import { page } from "../../../lib/page"
import { skuURL } from "../../../lib/sku"
import { isOutOfStockAllowed } from "../../../lib/product"

import Image from '../../Content/BlockHomePageHero/image'

// const { pages } = require('../../../config/pages.json')
// const cartPage = pages.find(i => i.id === 'cart')

// @todo use correct url for giftlist item

const CartSideBarItem = ({ store, provider, item, lang, close }) => {
  const { disabled, removing } =
    store && store.cart ? store.cart : { disabled: false, removing: false }

  const removeItem = async e => {
    e.preventDefault()
    provider.removeItem(item, lang)
  }

  return (
    <div className="article-to-be-deleted">
      <div className="d-flex">
        <div className="flex-column">
          <PageLink to={item.id_wishlist_product ? `/nl/wenslijsten/schenken?id=${item.id_wishlist}&item=${item.id_wishlist_product}` : skuURL(item, lang)} onClick={close}>
            {item.id_default_image ? (
              <img
                src={`${
                  process.env.GATSBY_CDN_URL
                }/p/${item.id_default_image.toString().split("").join("/")}/${
                  item.id_default_image
                }-small_default.jpg`}
                alt={item[`name_${lang.suffix}`]}
              />
            ) : (
              ""
            )}
          </PageLink>
        </div>
        <div className="flex-column info">
          <div className="d-flex justify-content-between">
            <div>{item.manufacturer_hidden ? "" : item.manufacturer_name}</div>
            <div>
              {l("field-quantity", lang)}: {item.quantity}
            </div>
          </div>
          <div className="name">
            <h5>
              {item[`name_${lang.suffix}`]}
              {item.combination ? ` - ${item.combination}` : ""}
            </h5>
          </div>
          <div className="price d-flex">
            <h5>&euro; {Number(item.sellPrice_tax_incl).toFixed(2)}</h5>
            {+item.applied_reduction_quantity > 0 ? (
              <p>
                <span className="badge badge-secondary ml-1">
                  {item.required_reduction_quantity}+
                  {item.applied_reduction_quantity}
                </span>
              </p>
            ) : +item.applied_reduction_amount > 0 ? (
              <p>
                <span className="badge badge-secondary ml-1">
                  -{Number(item.applied_reduction_amount).toFixed(2)}&euro;
                </span>
              </p>
            ) : +item.applied_reduction_percent > 0 ? (
              <p>
                <span className="badge badge-secondary ml-1">
                  -{Number(item.applied_reduction_percent).toFixed(0)}%
                </span>
              </p>
            ) : +item.reduction_percent > 0 ? (
              <p>
                <span className="badge badge-secondary ml-1">
                  -{Number(item.reduction_percent).toFixed(0)}%
                </span>
              </p>
            ) : (
              ""
            )}
            {item.giftlist_name ? (
              <div className="ml-1">
                <i className="fas fa-gift mr-1"></i> {item.giftlist_name}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex justify-content-between">
            {item.removed ? (
              <h5 className="badge badge-danger">
                <i className="fas fa-times"></i>{" "}
                {l("label-cartitem-removed", lang)}
              </h5>
            ) : (
              <>
                {item.display_type === "evoucher" ? (
                  <span></span>
                ) : (
                  <SKUAvailabilityLabel sku={item} lang={lang} />
                )}
                <button
                  className="btn btn-link"
                  disabled={disabled || item.removed}
                  onClick={removeItem}
                >
                  <i
                    className={`fas ${
                      removing && removing === item.id
                        ? "fa-spin fa-spinner"
                        : "fa-times"
                    }`}
                  ></i>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CartSideBar = ({
  close,
  store,
  cartProvider,
  layoutProvider,
  data,
  lang,
}) => {
  const { cart, disabled, removingvoucher } =
    store && store.cart
      ? store.cart
      : { cart: null, disabled: false, removingvoucher: null }

  const [confirmed, setConfirmed] = useState(false)
  const [removing, setRemoving] = useState(false)

  const onAddVoucher = e => {
    if (e) e.preventDefault()
    layoutProvider.showAddVoucher({
      close: () => layoutProvider.showCartResult(),
    })
  }

  const onRemoveVoucher = (e, rule) => {
    if (e) e.preventDefault()
    cartProvider.removeVoucher(rule)
  }

  const removeItem = async (e, item) => {
    e.preventDefault()
    setRemoving(true)
    cartProvider.removeItem(item, lang)
  }

  const confirm = e => {
    if (e) e.preventDefault()
    setConfirmed(true)
  }

  const cartIsNotEmpty =
    cart &&
    cart._embedded &&
    cart._embedded.items &&
    cart._embedded.items.length > 0

  if (
    !confirmed &&
    data &&
    data.event === "addItem" &&
    data.sku &&
    cart &&
    cart._embedded &&
    cart._embedded.items
  ) {
    const item = cart._embedded.items.find(
      i =>
        +i.id_product === +data.sku.id_product &&
        +i.id_product_attribute === +data.sku.id_product_attribute
    )

    if (item) {
      if (+item.quantity > +item.stock_quantity) {
        if (isOutOfStockAllowed(+item.allow_out_of_stock)) {
          return (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3>{l("msg-added-to-cart-outofstock", lang)}</h3>
                </div>
                <button onClick={close} className="btn btn-link clickable">
                  <i className="fas fa-times fa-lg"></i>
                </button>
              </div>

              <div className="summary mt-3">
                <div className="alert">
                  <p>
                    <span className="badge badge-primary">{item.quantity}</span>{" "}
                    x {t(item, "name", lang)}{" "}
                    {l("msg-added-to-cart-outofstock-1", lang)}
                  </p>
                  <p>&nbsp;</p>
                  <p>{l("msg-added-to-cart-outofstock-2", lang)}</p>
                </div>
              </div>

              <div className="un-modal-buttons d-flex justify-content-between align-items-center">
                <button
                  className="btn-medium-light"
                  disabled={disabled}
                  onClick={e => removeItem(e, item)}
                >
                  <i
                    className={`fas ${
                      removing ? "fa-spin fa-spinner" : "fa-times"
                    }`}
                  ></i>{" "}
                  {l("btn-added-to-cart-outofstock-no", lang)}
                </button>
                <button
                  className="btn-medium-secondary"
                  disabled={disabled}
                  onClick={confirm}
                >
                  <i className="fas fa-check"></i>{" "}
                  {l("btn-added-to-cart-outofstock-yes", lang)}
                </button>
              </div>
            </div>
          )
        }
      }
    }
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        {data && data.event === "addItem" ? (
          <div>
            <h3>
              {data.sku[`name_${lang.suffix}`]} {l("msg-added-to-cart", lang)}
            </h3>
          </div>
        ) : (
          <div>
            <h3>{l("cart-title", lang)}</h3>
          </div>
        )}
        <button onClick={close} className="btn btn-link clickable">
          <i className="fas fa-times fa-lg"></i>
        </button>
      </div>

      {data && data.event === "error" && (
        <div className="alert alert-danger mt-3">
          <p>{l(`error-cart-${data.error}`, lang)}</p>
          {data && data.product && (
            <p>
              {l(`info-cart-${data.error}`, lang).replace(
                "{quantity}",
                data.product.stock_quantity
              )}
            </p>
          )}
        </div>
      )}

      <div className="row m-3">
        <div className="col-12">
          <Image className="hero-image" alt="" lang={lang} />
        </div>
      </div>

      {cart && cart._embedded && cart._embedded.items ? (
        cart._embedded.items.map(item => (
          <CartSideBarItem
            key={`article-to-be-deleted-${item.id}`}
            store={store}
            provider={cartProvider}
            item={item}
            lang={lang}
            close={close}
          />
        ))
      ) : (
        <div className="article-to-be-deleted">
          <div className="d-flex">
            <div className="flex-column">
              <p>{l("msg-empty-cart", lang)}</p>
            </div>
          </div>
        </div>
      )}

      {cartIsNotEmpty && (
        <>
          <div className="">
            <button
              className="btn btn-outline-secondary btn-block"
              onClick={onAddVoucher}
              disabled={disabled}
            >
              {l("cart-have-promo-code", lang)}{" "}
              <i className="fas fa-chevron-right mx-1"></i>
            </button>
          </div>

          <div className="un-modal-buttons d-flex justify-content-between align-items-center">
            <PageLink
              to={t(page("cart"), "path", lang)}
              onClick={e => close()}
              className="btn-medium-light"
              disabled={disabled}
            >
              {l("button-cart", lang)}
            </PageLink>
            <CheckoutButton
              lang={lang}
              onClick={close}
              disabled={disabled || !cart || !cart.valid}
            >
              {l("button-checkout", lang)}
            </CheckoutButton>
          </div>

          {l("msg-webshop-attention", lang) && (
            <div className="summary mt-3">
              <div className="alert alert-warning">
                {l("msg-webshop-attention", lang)}
              </div>
            </div>
          )}

          <div className="summary mt-3">
            <h3>{l("cart-summary", lang)}</h3>
            <div className="summary-line d-flex justify-content-between">
              <p>{l("cart-subtotal", lang)}</p>
              <p>&euro; {Number(cart.totals.total_products_wt).toFixed(2)}</p>
            </div>
            <div className="summary-line d-flex justify-content-between">
              <p>{l("cart-shipping", lang)}</p>
              <p>
                &euro; {Number(cart.totals.total_shipping_tax_incl).toFixed(2)}
              </p>
            </div>
            <div className="summary-line d-flex justify-content-between">
              <p>{l("cart-discount", lang)}</p>
              <p>
                - &euro;{" "}
                {Number(cart.totals.total_discounts_tax_incl).toFixed(2)}
              </p>
            </div>
            {cart && cart._embedded && cart._embedded.rules ? (
              <>
                <hr />
                {cart._embedded.rules.map(rule => {
                  return (
                    <div
                      className="summary-line d-flex justify-content-between"
                      key={`cart-rule-${rule.id_cart_rule}`}
                    >
                      <p>
                        {rule._links && rule._links.remove ? (
                          <button
                            className="btn btn-link"
                            disabled={disabled}
                            data-rule={rule}
                            onClick={e => onRemoveVoucher(e, rule)}
                          >
                            <i
                              className={`fas ${
                                removingvoucher &&
                                removingvoucher === rule.id_cart_rule
                                  ? "fa-spin fa-spinner"
                                  : "fa-times"
                              }`}
                            ></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <span>
                          {rule[`description_${lang.suffix}`] ||
                            rule.code ||
                            rule[`name_${lang.suffix}`]}
                        </span>
                      </p>
                      {rule.computed_amount > 0 ? (
                        <p className="pt-1">
                          - &euro; {Number(rule.computed_amount).toFixed(2)}
                        </p>
                      ) : rule.reduction_amount ? (
                        <p className="pt-1">
                          - &euro; {Number(rule.reduction_amount).toFixed(2)}
                        </p>
                      ) : rule.reduction_percent ? (
                        <p className="pt-1">
                          - {Number(rule.reduction_percent).toFixed(0)}%
                        </p>
                      ) : null}
                    </div>
                  )
                })}
              </>
            ) : (
              ""
            )}
            <hr />
            <div className="summary-line d-flex justify-content-between">
              <p>{l("cart-total-incl-vat", lang)}</p>
              <p>&euro; {Number(cart.totals.total_paid_tax_incl).toFixed(2)}</p>
            </div>
            <div className="summary-line d-flex justify-content-between">
              <p>{l("cart-vat", lang)}</p>
              <p>&euro; {Number(cart.totals.total_vat).toFixed(2)}</p>
            </div>
            <hr />
            <div className="summary-line summary-features">
              <ul>
                <li>
                  <i className={`fas fa-check fa-xs mr-2`} />
                  {l("cart-features-shipping", lang)}
                </li>
                <li>
                  <i className={`fas fa-check fa-xs mr-2`} />
                  {l("cart-features-personal", lang)}
                </li>
                <li>
                  <i className={`fas fa-check fa-xs mr-2`} />
                  {l("cart-features-packing", lang)}
                </li>
                <li>
                  <i className={`fas fa-check fa-xs mr-2`} />
                  {l("cart-features-retour", lang)}
                </li>
                <li>
                  <i className={`fas fa-check fa-xs mr-2`} />
                  {l("cart-features-shop", lang)}
                </li>
              </ul>
            </div>
            <div className="summary-line summary-paymentmethods">
              <ul>
                <li>
                  <img src="https://www.mollie.com/external/icons/payment-methods/bancontact.svg" />
                </li>
                <li>
                  <img src="https://www.mollie.com/external/icons/payment-methods/ideal.svg" />
                </li>
                <li>
                  <img src="https://www.mollie.com/external/icons/payment-methods/creditcard.svg" />
                </li>
                <li>
                  <img src="https://www.mollie.com/external/icons/payment-methods/paypal.svg" />
                </li>
                <li>
                  <img src="https://www.mollie.com/external/icons/payment-methods/kbc.svg" />
                </li>
                <li>
                  <img src="https://www.mollie.com/external/icons/payment-methods/belfius.svg" />
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

CartSideBar.propTypes = {
  close: PropTypes.func,
  store: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.object,
}

export default CartSideBar
