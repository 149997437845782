  import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ alt, lang }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImages: allFile(filter: {base: {in: ["hero-20250111-nl.jpg"]}, relativeDirectory: {eq: "hero"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 600, quality: 100, webpQuality: 100) {
                originalName
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  if (data && data.placeholderImages) {
    const edge = data.placeholderImages.edges.find(i => i.node.childImageSharp.fluid.originalName === `hero-20250111-nl.jpg`)
    return <Img alt={alt} fluid={edge.node.childImageSharp.fluid} />
  }
  return ''
}

export default Image
